import { ITagManagementState, ITagDescription } from './tag.model';
import * as TagActions from './tag.actions';

export const initTags: ITagManagementState = {
    questionMappingTagState: {
        selectedQuestionMappingId: '',
        selectedQuestionMappingText: '',
        selectedCategory: '',
        questionMappingTags: [],
        isLoading: false,
        error: null
    },
    tagState: {
        alltags: [],
        isLoading: false,
        error: null
    },
    addTagState: {
        isLoading: false,
        success: false,
        error: null
    },
    removeTagState: {
        isLoading: false,
        success: false,
        error: null
    }
};

// Reducer
export function tagReducer(state = initTags, action: TagActions.Actions): ITagManagementState {
    switch (action.type) {
        case TagActions.SELECT_QUESTION_FOR_QUESTION_MAPPING_TAG: {
            return {
                ...state,
                questionMappingTagState: {
                    ...state.questionMappingTagState,
                    selectedQuestionMappingId: action.payload.questionMappingId,
                    selectedQuestionMappingText: action.payload.questionText,
                    selectedCategory: action.payload.categoryDescription

                }
            };
        }
        case TagActions.LOAD_TAGS_FOR_QUESTION_MAPPING: {
            return {
                ...state,
                questionMappingTagState: {
                    ...state.questionMappingTagState,
                    isLoading: true,
                    error: null
                }
            };
        }
        case TagActions.LOAD_TAGS_FOR_QUESTION_MAPPING_SUCCESS: {
            return {
                ...state,
                questionMappingTagState: {
                    ...state.questionMappingTagState,
                    questionMappingTags: action.payload.tags,
                    isLoading: false,
                    error: null
                }
            };
        }
        case TagActions.LOAD_TAGS_FOR_QUESTION_MAPPING_FAILURE: {
            return {
                ...state,
                questionMappingTagState: {
                    ...state.questionMappingTagState,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }
        case TagActions.LOAD_ALL_TAGS: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    isLoading: true,
                    error: null
                }
            };
        }
        case TagActions.LOAD_ALL_TAGS_SUCCESS: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    alltags: action.payload.tags,
                    isLoading: false,
                    error: null
                }
            };
        }
        case TagActions.LOAD_ALL_TAGS_FAILURE: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }
        case TagActions.ADD_TAG_TO_QUESTION_MAPPING: {
            return {
                ...state,
                addTagState: {
                    isLoading: true,
                    success: false,
                    error: null
                }
            };
        }

        case TagActions.ADD_TAG_TO_QUESTION_MAPPING_SUCCESS: {
            return {
                ...state,
               addTagState: {
                    isLoading: false,
                    success: true,
                    error: null
                }
            };
        }

        case TagActions.ADD_TAG_TO_QUESTION_MAPPING_FAILURE: {
            return {
                ...state,
                addTagState: {
                    isLoading: false,
                    success: false,
                    error: action.payload.error
                }
            };
        }
        case TagActions.REMOVE_TAG_FROM_QUESTION_MAPPING: {
            return {
                ...state,
                removeTagState: {
                    isLoading: true,
                    success: false,
                    error: null
                }
            };
        }
        case TagActions.REMOVE_TAG_FROM_QUESTION_MAPPING_SUCCESS: {
            return {
                ...state,
                removeTagState: {
                    isLoading: false,
                    success: true,
                    error: null
                }
            };
        }
        case TagActions.REMOVE_TAG_FROM_QUESTION_MAPPING_FAILURE: {
            return {
                ...state,
                removeTagState: {
                    isLoading: false,
                    success: false,
                    error: action.payload.error
                }
            };
        }
        case TagActions.CREATE_TAG: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    isLoading: true,
                    error: null
                }
            };
        }
        case TagActions.CREATE_TAG_SUCCESS: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    alltags: [...state.tagState.alltags, action.payload.tag],
                    isLoading: false,
                    error: null
                }
            };
        }
        case TagActions.CREATE_TAG_FAILURE: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }
        case TagActions.UPDATE_TAG: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    isLoading: true,
                    error: null
                }
            };
        }
        case TagActions.UPDATE_TAG_SUCCESS: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    alltags: state.tagState.alltags.map(tag => {
                        if (tag.tagVal === action.payload.tag.tagVal) {
                            return action.payload.tag;
                        }
                        return tag;
                    }),
                    isLoading: false,
                    error: null
                }
            };
        }
        case TagActions.UPDATE_TAG_FAILURE: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }
             case TagActions.DEACTIVATE_TAG: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    isLoading: true,
                    error: null
                }
            };
        }
        case TagActions.DEACTIVATE_TAG_SUCCESS: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    alltags: state.tagState.alltags.filter(tag => tag.tagVal !== action.payload.tagVal),
                    isLoading: false,
                    error: null
                }
            };
        }
        case TagActions.DEACTIVATE_TAG_FAILURE: {
            return {
                ...state,
                tagState: {
                    ...state.tagState,
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }    
        default:
            return state;
    }
}

// Question Mapping Tags selectors
export const getQuestionMappingTags = (state: ITagManagementState) => state.questionMappingTagState.questionMappingTags;
export const getQuestionMappingTagsLoading = (state: ITagManagementState) => state.questionMappingTagState.isLoading;
export const getQuestionMappingTagsError = (state: ITagManagementState) => state.questionMappingTagState.error;
export const getSelectedQuestionMappingId = (state: ITagManagementState) => state.questionMappingTagState.selectedQuestionMappingId;
export const getSelectedQuestionMappingText = (state: ITagManagementState) => state.questionMappingTagState.selectedQuestionMappingText;
export const getSelectedCategory = (state: ITagManagementState) => state.questionMappingTagState.selectedCategory;
export const getAddTagToQuestionMappingSuccess = (state: ITagManagementState) => state.addTagState.success;
export const getRemoveTagFromQuestionMappingSuccess = (state: ITagManagementState) => state.removeTagState.success;
export const getAddTagToQuestionMappingError = (state: ITagManagementState) => state.addTagState.error;

// Tags selectors
export const getAllTags = (state: ITagManagementState) => state.tagState.alltags;
export const getAllTagsLoading = (state: ITagManagementState) => state.tagState.isLoading;
export const getAllTagsError = (state: ITagManagementState) => state.tagState.error;