import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '@app/store';
import * as fromCategoryList from './list';
import * as fromCategoryDetail from './detail';
import * as fromCategoryTemplates from './template';
import * as fromTags from './tag';

export interface CategoryManagementState {
    details: fromCategoryDetail.CategoryDetails;
    list: fromCategoryList.ListModel;
    sections: fromCategoryDetail.ISections;
    templates: fromCategoryTemplates.ITemplate;
    tags: fromTags.ITagManagementState;
}

export interface State extends fromRoot.State {
    categoryManagement: CategoryManagementState;
}

export const reducers: ActionReducerMap<CategoryManagementState> = {
    details: fromCategoryDetail.categoryReducer,
    list: fromCategoryList.listReducer,
    sections: fromCategoryDetail.sectionsReducer,
    templates: fromCategoryTemplates.templateReducer,
    tags : fromTags.tagReducer
};

/**
 *  SELECTORS
 *  every reducer has their own selectors, but they're are not aware of their parent nodes
 *  createSelector is used to connect them to the root reducer
 */

// createFeatureSelector selects a part of the root store and allows us to access the its branches
export const getCategoryManagementState = createFeatureSelector<CategoryManagementState>('categoryManagement');
export const getCategoryDetailState = createSelector(getCategoryManagementState, state => state.details);
export const getCategoryListState = createSelector(getCategoryManagementState, state => state.list);
export const getCategorySectionsState = createSelector(getCategoryManagementState, state => state.sections);
export const getCategoryTemplateState = createSelector(getCategoryManagementState, state => state.templates);
export const getCategoryTagsState = createSelector(getCategoryManagementState, state => state.tags);

// category details selectors
export const getCategoryDetails = createSelector(getCategoryDetailState, fromCategoryDetail.selectors.getCategoryDetails);
export const getCategoryId = createSelector(getCategoryDetailState, fromCategoryDetail.selectors.getCategoryId);
export const getFlightSectionInfo = createSelector(getCategoryDetailState, fromCategoryDetail.selectors.getFlightSectionInfo);
export const getQuestionsSectionInfo = createSelector(getCategoryDetailState, fromCategoryDetail.selectors.getQuestionsSectionInfo);
export const getNotesSectionInfo = createSelector(getCategoryDetailState, fromCategoryDetail.selectors.getNotesSectionInfo);
export const getRecommendationsSectionInfo = createSelector(getCategoryDetailState, fromCategoryDetail.selectors.getRecommendationsSectionInfo);
export const getImagesSectionInfo = createSelector(getCategoryDetailState, fromCategoryDetail.selectors.getImagesSectionInfo);
export const getCategoryAttrs = createSelector(getCategoryDetailState, fromCategoryDetail.selectors.getCategoryAttrs);
export const getCategoryDescription = createSelector(getCategoryDetailState, fromCategoryDetail.selectors.getCategoryDescription);

// category list selectors
export const getProfiles = createSelector(getCategoryListState, fromCategoryList.getProfiles);
export const getSelectedProfile = createSelector(getCategoryListState, fromCategoryList.getSelectedProfile);
export const getSelectedProfileId = createSelector(getCategoryListState, fromCategoryList.getSelectedProfileId);
export const getByProfileLoading = createSelector(getCategoryListState, fromCategoryList.getByProfileLoading);
export const getProfilesList = createSelector(getCategoryListState, fromCategoryList.getProfilesList);
export const getCategories = createSelector(getCategoryListState, fromCategoryList.getCategories);
export const getCategoriesByProfileId = createSelector(getCategoryListState, fromCategoryList.getCategoriesByProfileId);
export const getAllLoading = createSelector(getCategoryListState, fromCategoryList.getAllLoading);
export const getAllCategories = createSelector(getCategoryListState, fromCategoryList.getAllCategories);

// category sections selectors
export const getFlightSection = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getFlightSection);
export const getNotesSection = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getNotesSection);
export const getFlightQuestions = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getFlightQuestions);
export const getNotesQuestions = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getNotesQuestions);
export const getGeneralQuestions = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getGeneralQuestions);
export const getQuestionsFlags = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getQuestionsFlags);
export const getQuestionsOpenedById = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getQuestionsOpenedById);
export const getAnswersOpenedById = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getAnswersOpenedById);
export const getOpenedQuestions = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getQuestionsOpenedById);
export const getOpenedAnswers = createSelector(getCategorySectionsState, fromCategoryDetail.sectionsSelectors.getAnswersOpenedById);

// // category template selectors
export const getTemplateNames = createSelector(getCategoryTemplateState, fromCategoryTemplates.getTemplateNames);
export const getExistingTemplates = createSelector(getCategoryTemplateState, fromCategoryTemplates.getExistingTemplates);
export const getTemplateQuestions = createSelector(getCategoryTemplateState, fromCategoryTemplates.getTemplateQuestions);
export const getSelectedAnswer = createSelector(getCategoryTemplateState, fromCategoryTemplates.getSelectedAnswer);

// category tags selectors
export const getQuestionMappingTags = createSelector(getCategoryTagsState, fromTags.getQuestionMappingTags);
export const getQuestionMappingTagsLoading = createSelector(getCategoryTagsState, fromTags.getQuestionMappingTagsLoading);
export const getQuestionMappingTagsError = createSelector(getCategoryTagsState, fromTags.getQuestionMappingTagsError);
export const getSelectedQuestionMappingId = createSelector(getCategoryTagsState, fromTags.getSelectedQuestionMappingId);
export const getSelectedQuestionMappingText = createSelector(getCategoryTagsState, fromTags.getSelectedQuestionMappingText);
export const getSelectedCategory = createSelector(getCategoryTagsState, fromTags.getSelectedCategory);
export const getAddTagToQuestionMappingSuccess = createSelector(getCategoryTagsState, fromTags.getAddTagToQuestionMappingSuccess);
export const getRemoveTagToQuestionMappingSuccess = createSelector(getCategoryTagsState, fromTags.getRemoveTagFromQuestionMappingSuccess);
export const getAddTagToQuestionMappingError = createSelector(getCategoryTagsState, fromTags.getAddTagToQuestionMappingError);

export const getAllTags = createSelector(getCategoryTagsState, fromTags.getAllTags);
export const getAllTagsLoading = createSelector(getCategoryTagsState, fromTags.getAllTagsLoading);
export const getAllTagsError = createSelector(getCategoryTagsState, fromTags.getAllTagsError);