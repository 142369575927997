import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute,  Router } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUser, State } from 'app/store';
import { getCategoryDetails, getSelectedQuestionMappingText, getAllTags , getQuestionMappingTags , getSelectedCategory, getAddTagToQuestionMappingSuccess, getRemoveTagToQuestionMappingSuccess, getAddTagToQuestionMappingError } from '../category-management-store';
import * as Actions from '../category-management-store/tag/tag.actions';
import {  LoadCategoryDetail } from '../category-management-store/detail';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient/dynamic-questions.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TextAreaComponent } from '@progress/kendo-angular-inputs';
import { LoggerService } from 'app/shared/error-handler-notify/services';
import { ITagDescription } from '../category-management-store/tag';
import { RowClassArgs, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { IUser, User } from 'app/store/user';
import { ConfirmationPopupComponent } from 'app/shared/common-ux/components/confirmation-popup/confirmation-popup.component';
export interface availableTagDescription extends ITagDescription {
  isMapped: boolean;
  isActiveTag: boolean;
}
@Component({
  selector: 'app-question-tag-management',
  templateUrl: './question-tag-management.component.html',
  styleUrl: './question-tag-management.component.scss'
})

export class QuestionTagManagementComponent implements OnInit, OnDestroy , AfterViewInit {
  destroy$: Subject<void> = new Subject<void>();
  category$: Observable<string>;
  categoryId: number;
  profileId: number;
  questionMappingId: string;
  sort: SortDescriptor[] = [{field: "", dir: "asc"}];
  mappedTagVals: string[] = [];
  availableTags: availableTagDescription[]
  selectedTags: string[] = [];
  selectAllState: SelectAllCheckboxState = 'unchecked';
  description$: Observable<string>;
  rows:number = 1 ;
  isTextTruncated: boolean = false;
  isITUser: boolean = false;
  tagToRemove: any;
  questionMappingErrorMessage: string
  @ViewChild('textArea') textAreaElement: TextAreaComponent;
  @ViewChild('confirmQuestionTagRemove', { static: true }) confirmQuestionTagRemove: ConfirmationPopupComponent;  
  @ViewChild('addTagUserErrorModal', { static: true }) public addTagUserErrorModal;

  
  constructor(
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dynamicQuestionsService: DynamicQuestionsService,
    private logger : LoggerService,
  ) { }

  // Only IT Admins should be able to see System tags.
  public setPrivileges(user: IUser) {
    if (user) {
        this.isITUser = User.hasPrivilege(user, 'WEB_SUADMIN');
    }
  }

  ngOnInit() {
    this.store.select(getUser).pipe(
      take(1)
    ).subscribe(user => {
      this.setPrivileges(user);
    });
    
    this.activatedRoute.paramMap.pipe(
      switchMap(params => {
        this.profileId = +params.get('profileId');
        this.categoryId = +params.get('categoryId');
        this.questionMappingId = params.get('questionMappingId');
     
        this.category$ = this.getCategoryDetails(this.profileId, this.categoryId);
        this.description$ = this.getQuestionMetadata(this.questionMappingId);

        return this.prepareTagCollections();
      }),
      takeUntil(this.destroy$)
    ).subscribe(tags => {
      this.availableTags = tags.filter(tag => {
        const activeTag = this.checkTagActiveStatus(tag.effectiveDate, tag.inactiveDate);
        // If isITUser then show all tags (active and inactive), else show only active non-system tags.
        return this.isITUser || (activeTag && !tag.systemRequired);
      }).map(tag => ({
        ...tag,
        isActiveTag: this.checkTagActiveStatus(tag.effectiveDate, tag.inactiveDate)
      }))
    });
  }

  // Fetch the category details from the store's getSelectedCategory selector if not available in the store then dispatch an action to load the category details and select from getCategoryDetails selector
  getCategoryDetails(profileId: number, categoryId: number): Observable<string> {
    return this.store.select(getSelectedCategory).pipe(
      switchMap(category => {
        if (category) {
          return of(category);
        } else {
          this.store.dispatch(new LoadCategoryDetail(profileId, categoryId));
          return this.store.select(getCategoryDetails).pipe(map(category => category.description));
        }
      })
    );
  }

  ngAfterViewInit() {
    const interval = setInterval(() => {
      this.checkIfTextIsTruncated();
    }, 750);
    setTimeout(() => clearInterval(interval), 1500);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
 // Fetch the questionText and set the number of rows based on the length of the question
 getQuestionMetadata(questionMappingId: string): Observable<string> {
  return this.store.select(getSelectedQuestionMappingText).pipe(
    switchMap(questionText => {
      if (questionText) {
          // questionText is available in the store
          return of(questionText);
      } else {
          // questionText is not available in the store, make a network call
          return this.dynamicQuestionsService.getQuestionsMetaData(questionMappingId).pipe(
            map(data => {
              return data.question;
            }),
            catchError(error => {
              this.logger.error('Error fetching question metadata:', error);
              return of(null);
            })
          );
        }
      })
    );
  }

  prepareTagCollections(){
    // Checking privilege.
    const suAdmin = this.isITUser;
    
    // Dispatch actions to load all tags and mapped tags
    this.store.dispatch(new Actions.LoadAllTags());
    this.store.dispatch(new Actions.LoadTagsForQuestionMapping({ questionMappingId: this.questionMappingId }));

    // Select the data from the store
    return combineLatest({
      allTags: this.store.select(getAllTags),
      mappedTags: this.store.select(getQuestionMappingTags)
     
    }).pipe(
      debounceTime(0),
      map(({ allTags, mappedTags }) => {
        if(allTags.length>0 ){
            // Filter tags based on privileges.
          const filteredAllTags = suAdmin ? allTags: allTags.filter(tag => !tag.systemRequired);
          const filteredMappedTags = suAdmin ? mappedTags: mappedTags.filter(tag => !tag.systemRequired);
          return this.getAvailableTagsToMap(filteredAllTags, filteredMappedTags);
        }
      })
    );
  }

  getAvailableTagsToMap(allTags, mappedTags): availableTagDescription[] {
    const mappedTagVals = new Set(mappedTags.map(tag => tag.tagVal));
    //store the mapped tag values for showing the mapped tags in the multiselect
    this.mappedTagVals = Array.from(mappedTagVals) as string[];
    this.selectedTags = Array.from(mappedTagVals) as string[];
    return allTags.map(tag => ({...tag, isMapped: mappedTagVals.has(tag.tagVal) }))
  }

  gotoCategories() {
    this.router.navigate(['/admin/categorymanagement', { profileId: this.profileId }]);
  }

  gotoActiveCategory() {
    this.router.navigate(['/admin/categorymanagement/profile/' + this.profileId + '/category/' + this.categoryId + '/summary']);
  }

  checkIfTextIsTruncated() {
    const { scrollHeight, clientHeight } = this.textAreaElement.input.nativeElement;
      if (scrollHeight > 2 * clientHeight) {
        // Scroll height is more than double the client height, make rows=2 and show tooltip
        this.rows = 2;
        this.isTextTruncated = true; // Show tooltip
      } else if (scrollHeight > clientHeight && scrollHeight <= 2 * clientHeight) {
        // Scroll height is between clientHeight and 2 * clientHeight, make rows=2 but no tooltip
        this.rows = 2;
        this.isTextTruncated = false; // No tooltip
      } else {
        // Scroll height is less than clientHeight, keep rows=1 and no tooltip
        this.rows = 1;
        this.isTextTruncated = false;
      }
  }

  // Check if the tag is active based on effective and inactive dates.
  private checkTagActiveStatus(effectiveDate: Date, inactiveDate?: Date): boolean {
    const currentDate = new Date();
    if (!inactiveDate) {
      return true;
    }
    return effectiveDate <= currentDate && inactiveDate >= currentDate;
  }
  

  removeTag(tag: any): void {
    this.tagToRemove = tag;
    this.confirmQuestionTagRemove.open();
  }


  public onRemoveTagConfirm(): void {
    if (this.tagToRemove && this.questionMappingId) {
        const actionToRemoveQuestionTag = new Actions.RemoveTagFromQuestionMapping({
        questionMappingId: this.questionMappingId,
        tagVal: this.tagToRemove
      });
      this.store.dispatch(actionToRemoveQuestionTag);

      this.store.select(getRemoveTagToQuestionMappingSuccess).pipe(takeUntil(this.destroy$)).subscribe(success => {
        if (success) {
          if (this.selectAllState === 'checked') {
            this.selectAllState = 'unchecked';
          } 
        }
      });
    }else{
      this.logger.error('Tag or questionMappingId is not set');
    }
  }
  
  public onApply(): void {
    //selectedtags is the  array of selectedTags minus the mappedtagvals
    const selectedTags = this.selectedTags.filter((tagVal) => !this.mappedTagVals.includes(tagVal));
    this.store.dispatch(new Actions.AddTagToQuestionMapping({ questionMappingId: this.questionMappingId, tagVal: selectedTags }));

    this.store.select(getAddTagToQuestionMappingSuccess).pipe(takeUntil(this.destroy$)).subscribe(success => {
      if (success) {
        if (this.selectAllState === 'checked') {
          this.selectAllState = 'unchecked';
        } 

      }
      
    });

    this.store.select(getAddTagToQuestionMappingError).pipe(takeUntil(this.destroy$)).subscribe(errorMessage => {
      if (errorMessage && errorMessage !== "") {
        this.questionMappingErrorMessage = errorMessage;
        this.addTagUserErrorModal.open();
      }
    });     
  }

  public onEdit(dataItem: any): void {
    console.log('Edit clicked for: ', dataItem.tagVal);
  }

  public myRowSelectionKey(context): string {
    return context.dataItem.tagVal ;
  };


  public rowClass = (args: RowClassArgs) => ({
    'k-disabled': args.dataItem.isMapped, // Apply k-disabled if isMapped is true
  });
  
  public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
    this.selectAllState = checkedState;
    if (checkedState !== 'checked') {
      this.selectedTags = this.mappedTagVals;
    }
  };
} 