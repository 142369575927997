<div class="row">
  <ol class="breadcrumb">
    <li><a routerLink='/home'><i class="icon-home"></i> Home</a></li>
    <li><a routerLink="/admin/categorymanagement">Admin</a></li>
    <li><a routerLink="/admin/categorymanagement">Category Management</a></li>
    <li><a (click)="gotoActiveCategory()" class="new-link">{{ (category$ | async) }}</a></li>
    <li>Question Tags</li>
  </ol>
</div>
<div class="row align-items-center">
  <div class="button-icon col-auto ">
    <a (click)="gotoActiveCategory()" class="icon-sec-chevron-left text-decoration-none"></a>
  </div>
  <div class="col pe-0">
    <div class="section-title-cers">Question tags</div>
    <div class="section-line"></div>
  </div>
</div>
<div class="row pt-2">
  <div class="col-3 ps-0 align-items-center">
    <kendo-label text="Question">
      <div kendoTooltip position="bottom"  [showOn]="isTextTruncated ? 'hover' : 'none'" >
        <ng-container *ngIf="description$ | async as description">
          <kendo-textarea 
                [title]="description" 
                #textArea   
                [rows]="rows" 
                size="none" 
                [ngClass]="this.rows === 2 ? 'question-text-multiline' : 'question-text'"
                [readonly]="true" 
                resizable="none" 
                [value]="description">
          </kendo-textarea>
        </ng-container>
      </div>
    </kendo-label>
  </div>
  <div class="col-9 pe-0">
    <kendo-label text="Tag(s) applied">
      <kendo-multiselect [placeholder]="'None'" id='scrollstyle' [readonly]="true" [(ngModel)]="mappedTagVals">
        <ng-template kendoMultiSelectTagTemplate let-dataItem>
          <div class="d-flex align-items-center">
            <span>{{dataItem}}</span>
            <button class="icon-sec-x-circle-outline" (click)="removeTag(dataItem)"></button>
          </div>
        </ng-template>
      </kendo-multiselect>
    </kendo-label>
  </div>
</div>
<div class="row pt-4">
  <div class="col px-0">
    <div class="section-title-cers">Tags</div>
    <div class="section-line"></div>
  </div>
</div>
<div class="row py-3">
  <div class="d-flex justify-content-between align-items-center px-0">
    <kendo-textbox [ngClass]="'search-textbox'"></kendo-textbox>
    <button kendoButton class="new-tag">
      <div class="d-flex align-items-center justify-content-between">
        <i class='icon-sec-plus-outline new-tag-plus-icon'></i>
        <span>New tag</span>
      </div>
    </button>
    <button kendoButton  (click)="onApply()" class="apply-tag">Apply</button>
  </div>
</div>
<div class="row px-0">
  <kendo-grid 
    id='scrollstyle'
    [kendoGridBinding]="availableTags"
    [selectable]="{checkboxOnly: true, mode: 'multiple'}" 
    [rowClass]="rowClass"  
    [(selectedKeys)]="selectedTags"
    [kendoGridSelectBy]="myRowSelectionKey"
    [sortable]="true" 
    [pageSize]="10" 
    [pageable]="true"
    >
      <kendo-grid-checkbox-column [headerClass]="'k-checkBox-header'" [class]="'k-checkBox-cell'" >
        <ng-template kendoGridHeaderTemplate>
          <input
            type="checkbox"
            kendoCheckBox
            kendoGridSelectAllCheckbox
            [state]="selectAllState"
            (selectAllChange)="onSelectAllChange($event)"
          />
        </ng-template>     
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <input
            type="checkbox"
            kendoCheckBox
            [kendoGridSelectionCheckbox]="rowIndex"
            [checked]="dataItem.isMapped || selectedTags.includes(dataItem.tagVal)"
            [disabled]="dataItem.isMapped"
          />
        </ng-template>
      </kendo-grid-checkbox-column>

      <kendo-grid-column field="tagVal"  [headerClass]="'k-tagValue-header'" [class]="'k-tagValue-cell'">
        <ng-template kendoGridHeaderTemplate>
          <span class="k-column-title">Tag name</span> 
          <span *ngIf="sort[0].field == '' || sort[0].dir == null || sort[0].field !== column.field">
              <i class="icon-sec-sort-default"></i>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="tagDisplayTxt" [headerClass]="'k-tagDisplayText-header'" [class]="'k-tagDisplayText-cell'"> 
        <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
          <span class="k-column-title">Display text</span> 
          <span *ngIf="sort[0].field == '' || sort[0].dir == null || sort[0].field !== column.field">
            <i class="icon-sec-sort-default"></i  >
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="tagDescription" title="Description" [sortable]="false" [headerClass]="'k-tagDescription-header'" [class]="'k-tagDescription-cell'" ></kendo-grid-column>
      
      <kendo-grid-column field="piiInd" title="PII" [sortable]="false" [headerClass]="'k-piiInd-header'" [class]="'k-piiInd-cell'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.piiInd">Yes</span>
          <span *ngIf="!dataItem.piiInd">No</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="isITUser"  field="system" [sortable]="false" [headerClass]="'k-system-header'" [class]="'k-system-cell'" title="System">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.systemRequired">Yes</span>
          <span *ngIf="!dataItem.systemRequired">No</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="isITUser" field="status" [sortable]="false" [headerClass]="'k-status-header'" [class]="'k-status-cell'" title="Status">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.isActiveTag" label="Active" class="status-chip active-chip">Active</span>
          <span *ngIf="!dataItem.isActiveTag" label="Inactive" class="status-chip inactive-chip">Inactive</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="isITUser" field="edit" [sortable]="false" title="" [headerClass]="'k-edit-header'" [class]="'k-edit-cell'" >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="icon-sec-pencil-square" (click)="onEdit(dataItem)"></span>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoPagerTemplate let-currentPage="currentPage" let-totalPages="totalPages">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-grid-spacer></kendo-grid-spacer>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-grid-spacer></kendo-grid-spacer>
        <kendo-pager-page-sizes [pageSizes]="[10,15,20]"></kendo-pager-page-sizes>
      </ng-template>
  </kendo-grid>
</div>

<app-confirmation-popup #confirmQuestionTagRemove [title]=""
    [message]="'Are you sure you want to remove the tag from this question?'" (callAction)="onRemoveTagConfirm()">
</app-confirmation-popup>

<modal modalClass="modal-md" #addTagUserErrorModal>
  <modal-header>
      <i class="icon-error"></i>
      <h4 class="iconText">Unable to apply tags</h4>
  </modal-header>
  <modal-content>
      <p>{{ questionMappingErrorMessage }}</p>
  </modal-content>
  <modal-footer>
      <button class="btn-primary" (click)="addTagUserErrorModal.close()">Ok</button>
  </modal-footer>
</modal>  
