import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from 'app/shared/error-handler-notify/services';
import { IUser, User, getUser } from 'app/store/user';
import { State } from "@app/store";
import { catchError, combineLatest, debounceTime, forkJoin, map, of, Subject, take, takeUntil } from 'rxjs';
import * as Actions from '../../category-management-store/tag/tag.actions';
import { getQuestionMappingTags } from '../../category-management-store';
import { ITagDescription } from '../../category-management-store/tag';

@Component({
  selector: 'app-tags-view',
  templateUrl: './tags-view.component.html',
  styleUrls: ['./tags-view.component.scss']
})

export class TagsViewComponent implements OnInit, OnDestroy {
    @Input() questionMappingId: string = '';

    kendoPanelBarExpandMode: string  = 'single';
    tagsView: any[] = [];
    includeSystemRequired: boolean;
    destroy$ = new Subject<void>();

    constructor (
      private store: Store<State>,
      public logger: LoggerService, 
    ) {}

    // Only IT Admins should be able to see System tags.
    public setPrivileges(user: IUser) {
      if (user) {
          this.includeSystemRequired = User.hasPrivilege(user, 'WEB_SUADMIN');
      }
    }

    getMappedTags(): void {
      // Get the mapped tags for the question mapping id via the store.
      this.store.dispatch(new Actions.LoadTagsForQuestionMapping({ questionMappingId: this.questionMappingId }));

      // Select the data from the store.
      combineLatest([
        this.store.select(getUser),
        this.store.select(getQuestionMappingTags)
      ]).pipe(
        takeUntil(this.destroy$),
        map(([user, mappedTags]) => {
          // Check if the user and mappedTags are not null or empty.
          if (user && mappedTags && mappedTags.length > 0) {
            this.setPrivileges(user);
            this.processMappedTags(mappedTags);
          } else {
            // If no tags are returned, push an empty row.
            this.tagsView = [{
              title: 'No tags available.',
              disabled: true
            }];
          }
        }),
        catchError(error => {
          this.logger.error('Error fetching mapped tags.', error);
          return of([]); // Return an empty array if an error occurs.
        }),
      ).subscribe();
    }

    private processMappedTags(mappedTags: ITagDescription[]): void {
      const suAdmin = this.includeSystemRequired;

      // Filter tags based on privileges.
      const filteredMappedTags = suAdmin ? mappedTags : mappedTags.filter(tag => !tag.systemRequired);
      this.tagsView = filteredMappedTags.map(tag => ({
        title: tag.tagVal,
        children: [
          {
            title: tag.tagDescription || 'No description available.',
            disabled: true
          }
        ]
      }));
    }

    ngOnInit(): void {
      this.getMappedTags();
    }

    ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
    }
}
